
import { defineComponent, inject } from 'vue';

export default defineComponent({
  props: {
    dims: { type: Array, required: true },
    nolabels: { type: Boolean, default: false },
    overlap: { type: Boolean, default: false },
  },
  setup() {
    const convertClass = inject('convertClass') as Function;

    return {
      convertClass,
    };
  },
});

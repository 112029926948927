
import { computed, defineComponent, provide, ref } from 'vue';
import Debug from './components/util/Debug.vue';
import Notification from './components/Notification.vue';
import TopLine from './components/TopLine.vue';
import { mdiArrowUpThick } from '@mdi/js';

export default defineComponent({
  components: {
    Debug,
    TopLine,
    Notification,
  },
  setup() {
    const windowsize = ref(window.innerWidth);

    window.addEventListener('resize', () => {
      windowsize.value = window.innerWidth;
    });
    document.addEventListener('scroll', (e) => {
      const totop = document.getElementById('backToTop');
      if (!totop) return;
      if (document.documentElement.scrollTop > 300) {
        totop.style.opacity = '1';
      } else {
        totop.style.opacity = '0';
      }
    });

    provide('convertClass', (skill: string) => {
      switch (skill.toLowerCase()) {
        case 'kreativität':
          return 'kreativitaet';
        case 'konzeption':
          return 'konzeption';
        case 'tools':
          return 'tools';
        case 'technische skills':
          return 'technische';
        case 'methodik':
          return 'methodik';
        case 'erfahrung/interesse':
        case 'interesse':
        case 'erfahrung':
          return 'erfahrung';
        case 'spezifische kenntnisse':
          return 'spezifisch';
      }
    });

    provide(
      'breakpoint',
      computed(() => {
        // https://antoniandre.github.io/wave-ui/breakpoints
        if (windowsize.value <= 600) return { name: 'xs', value: 1 };
        if (windowsize.value <= 900) return { name: 'sm', value: 2 };
        if (windowsize.value <= 1200) return { name: 'md', value: 3 };
        if (windowsize.value <= 1700) return { name: 'ld', value: 4 };
        return { name: 'xl', value: 5 };
      })
    );

    return {
      mdiArrowUpThick,
    };
  },
});

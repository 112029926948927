import { AxiosResponse } from 'axios';
import { set } from './_util';

const state = {
  skills: undefined,
  myskills: undefined,
};

function colorForDim(name: string) {
  switch (name) {
    case 'Kreativität':
      return 'kreativitaet';
    case 'Konzeption':
      return 'konzeption';
    case 'Tools':
      return 'tools';
    case 'Spezifische Kenntnisse':
      return 'spezifische-kenntnisse';
    case 'Methodik':
      return 'methodik';
    case 'Erfahrung':
      return 'erfahrungen';
    case 'Interesse':
      return 'kreativitaet';
    case 'Technische Skills':
      return 'technische-skills';
    default:
      return 'pink';
  }
}

const actions = {
  fetchCompententForSkill(context: any, id: string) {
    return context.dispatch('API', { method: 'get', url: `/list/skills/${id}/competent` }).then((res: AxiosResponse) => res.data);
  },
  fetchTopXForSkill(context: any, { id, number }: { id: string; number: number }) {
    return context.dispatch('API', { method: 'get', url: `/list/skills/${id}/top/${number}` }).then((res: AxiosResponse) => res.data);
  },
  fetchSkills(context: any) {
    // if (context.state.skills) return context.state.skills;
    return context.dispatch('API', { method: 'get', url: '/list/skills' }).then((res: AxiosResponse) => {
      context.commit('setSkills', res.data);
      return context.state.skills;
    });
  },
  fetchSkillsForCurrentUser(context: any) {
    return context.dispatch('API', { method: 'get', url: '/list/skills/mine' }).then((res: AxiosResponse) => {
      context.commit('setMySkills', res.data);
      return context.state.mySkills;
    });
  },
  fetchPointDistroForSkill(context: any, id: string) {
    return context.dispatch('API', { method: 'get', url: `/list/skills/${id}/analyse` }).then((res: AxiosResponse) => res.data);
  },
  fetchSkillsForUser(context: any, id: string) {
    return context.dispatch('API', { method: 'get', url: `/list/skills/${id}` }).then((res: AxiosResponse) => res.data);
  },
  fetchSkillInfo(context: any, id: string) {
    return context.dispatch('API', { method: 'get', url: `/skill/${id}` }).then((res: AxiosResponse) => {
      const dim1 = {
        name: res.data.dim1,
        color: colorForDim(res.data.dim1),
      };
      const dim2 = {
        name: res.data.dim2,
        color: colorForDim(res.data.dim2),
      };

      return { name: res.data.name, group: res.data.group, dim1, dim2 };
    });
  },
  updateSkill(context: any, data: { id: string; skillid: string; grade: object; reason: string }) {
    return context.dispatch('API', { method: 'post', url: '/manage/grades/update', data });
  },
};

const getters = {};
const mutations = {
  setSkills: set('skills'),
  setMySkills: set('mySkills'),
};

export default {
  state,
  getters,
  mutations,
  actions,
};

<style lang="scss">
@import 'scss/fonts';
@import 'scss/color-classes';
@import 'scss/global';

#app {
  font-size: $base-font-size;
  font-family: 'HelveticaNeueLTPro-Lt';
  color: #333;
  font-size: 14px;

  h1 {
    text-align: center;
    font-family: 'HelveticaNeueLTPro-Bd';
    font-size: 32px;
    text-transform: uppercase;
    margin: 0;
  }

  h2 {
    text-align: center;
    margin: 0;
    font-size: 16px;
    font-family: 'HelveticaNeueLTPro-Lt';
  }

  .headline {
    margin-bottom: 55px;
  }

  .headline + .description {
    margin-top: -115px + 49px;
    margin-bottom: 115px;
  }

  #backToTop {
    text-align: center;
    z-index: 100;
    opacity: 1;
    opacity: 0;
    /*@media(max-width:992px) {
      position:fixed;
      right:10px;
      bottom:10px;
    }*/
    position: fixed;
    right: 5px;
    bottom: 5px;
    background: #f9f9f9;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
    @media (max-width: 992px) {
      right: 0;
      bottom: 0px;
    }

    a {
      display: block;
      text-align: center;
      margin: 0;
      outline: none;
      padding: 5px;
      .backtotop-icon {
        color: $c-smd-lachs;
        padding: 0;
        margin: 0;
        display: block;
        position: relative;
        left: 0px;
        width: 30px;
        height: 30px;
        line-height: 30px;
        top: 0px;
        font-size: 160%;
        -webkit-transition: all 500ms ease;
        -moz-transition: all 500ms ease;
        -ms-transition: all 500ms ease;
        -o-transition: all 500ms ease;
        transition: all 500ms ease;
        @media (max-width: 992px) {
          width: 40px;
          height: 40px;
          line-height: 40px;
          background-color: rgba($color: #fff, $alpha: 0.9);
        }
      }
    }
    &:hover {
      bottom: 10px;
      span {
        color: #212121;
        @media (max-width: 992px) {
          top: 0;
        }
      }
    }
  }
  textarea,
  input[type='text'],
  input[type='file'],
  input[type='button'],
  input[type='password'],
  input[type='submit'] {
    border: 1px solid #c4c8cc;
    padding: 5px;
  }

  button,
  .btn {
    letter-spacing: 2px;
    border: 0;
    border-radius: 0;
    position: relative;
    top: 0;
    left: 0;
    background: none;
    color: #666;
    padding-right: 25px;
    border: 1px solid #eee;
    padding: 12px 20px 12px 20px;
    svg {
      width: 30px;
      height: 30px;
      margin-right: 5px;
      position: relative;
      top: -1px;
      left: -5px;
      fill: #666;
    }
    &:hover {
      color: #fff;
      border: 1px solid #fff;
      svg {
        fill: #fff;
      }
    }

    transition: 0.2s all;

    &:hover {
      background-color: $c-smd-blue-highlight;
    }

    &.red {
      &:hover {
        background-color: $c-red-highlight;
      }
    }
  }

  .description {
    text-align: center;
  }

  .form-check-label {
    margin-left: 10px;
  }

  .form-element {
    position: relative;
    margin-top: 25px;
    width: 100%;
    input,
    textarea,
    select {
      width: 100%;
      border-radius: 0;
      border: 0;
      background-color: #fff;
      color: #000;
      transition: all 0.2s;
      border: 1px solid #c4c8cc;
      padding: 15px;

      &:hover,
      &:focus {
        background-color: #fff;
        border: 1px solid #000;
        outline: none;
        box-shadow: none;
      }
    }

    .form-control {
      padding: 15px;
      // ''padding-top: 17px;
    }

    label {
      transition: all 0.2s;
      position: absolute;
      transform: translate(0, 0);
      background-color: #fff;
      padding-left: 4px;
      padding-right: 4px;
      opacity: 0;
      // top: -12px;
      // left: -5px;
    }
  }
  .form-element {
    label.hasvalue {
      transform: translate(12px, -15px);
      opacity: 1;
    }
  }
}
</style>

<template>
  <div>
    <a name="top" id="top"></a>
    <div id="backToTop">
      <a href="#top" v-smooth-scroll><Icon :path="mdiArrowUpThick" class="backtotop-icon"/></a>
    </div>
    <router-view> </router-view>
    <!-- <Debug /> -->
    <Notification />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, provide, ref } from 'vue';
import Debug from './components/util/Debug.vue';
import Notification from './components/Notification.vue';
import TopLine from './components/TopLine.vue';
import { mdiArrowUpThick } from '@mdi/js';

export default defineComponent({
  components: {
    Debug,
    TopLine,
    Notification,
  },
  setup() {
    const windowsize = ref(window.innerWidth);

    window.addEventListener('resize', () => {
      windowsize.value = window.innerWidth;
    });
    document.addEventListener('scroll', (e) => {
      const totop = document.getElementById('backToTop');
      if (!totop) return;
      if (document.documentElement.scrollTop > 300) {
        totop.style.opacity = '1';
      } else {
        totop.style.opacity = '0';
      }
    });

    provide('convertClass', (skill: string) => {
      switch (skill.toLowerCase()) {
        case 'kreativität':
          return 'kreativitaet';
        case 'konzeption':
          return 'konzeption';
        case 'tools':
          return 'tools';
        case 'technische skills':
          return 'technische';
        case 'methodik':
          return 'methodik';
        case 'erfahrung/interesse':
        case 'interesse':
        case 'erfahrung':
          return 'erfahrung';
        case 'spezifische kenntnisse':
          return 'spezifisch';
      }
    });

    provide(
      'breakpoint',
      computed(() => {
        // https://antoniandre.github.io/wave-ui/breakpoints
        if (windowsize.value <= 600) return { name: 'xs', value: 1 };
        if (windowsize.value <= 900) return { name: 'sm', value: 2 };
        if (windowsize.value <= 1200) return { name: 'md', value: 3 };
        if (windowsize.value <= 1700) return { name: 'ld', value: 4 };
        return { name: 'xl', value: 5 };
      })
    );

    return {
      mdiArrowUpThick,
    };
  },
});
</script>

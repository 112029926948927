<style lang="scss">
#notification-container {
  position: fixed;
  top: 0;
  z-index: 999;
  right: 20px;

  .notification {
    background-color: $c-green-light;
    color: #fff;
    padding: 20px;
    &.error {
      background-color: $c-red;
    }
  }

  .notificationlist-enter-active,
  .notificationlist-leave-active {
    transition: all 0.5s ease;
  }
  .notificationlist-enter-from,
  .notificationlist-leave-to {
    opacity: 0;
    transform: translateY(30px);
  }
}
</style>

<template>
  <div id="notification-container">
    <transition-group name="notificationlist">
      <div class="notification" :class="notification.type" :key="index" v-for="(notification, index) in notifications">
        {{ notification.message }}
      </div>
    </transition-group>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  setup() {
    const store = useStore();
    const notifications = computed(() => store.state.notifications.notifications);
    store.dispatch('clearStore');

    return {
      notifications,
    };
  },
});
</script>

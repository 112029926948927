import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Index from '../views/Index.vue';
import store from '../store';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Login',
    component: Index,
  },
  {
    path: '/app',
    name: 'Appindex',
    redirect: '/app/overview',
    component: () => import(/* webpackChunkName: "Appindex" */ '../views/app/Index.vue'),
    children: [
      {
        path: 'overview',
        name: 'Overview',
        component: () => import(/* webpackChunkName: "Overview" */ '../views/app/Overview.vue'),
        meta: { headline: 'Überblick' },
      },
      {
        path: 'skills',
        name: 'Skills',
        component: () => import(/* webpackChunkName: "Skills" */ '../views/app/Skills.vue'),
        meta: { headline: 'Unsere Skills in der Styria Media Design' },
      },
      {
        path: 'skills/:id',
        name: 'SkillDetails',
        component: () => import(/* webpackChunkName: "SkillDetails" */ '../views/app/SkillDetails.vue'),
        meta: { headline: '!Skillname!' },
      },
      {
        path: 'profile',
        name: 'MyProfile',
        component: () => import(/* webpackChunkName: "MyProfile" */ '../views/app/MyProfile.vue'),
        meta: { headline: '!Name!' },
      },
      {
        path: 'mitarbeiter',
        name: 'Mitarbeiter',
        component: () => import(/* webpackChunkName: "Mitarbeiter" */ '../views/app/Mitarbeiter.vue'),
        meta: { headline: '!Name!' },
      },
      {
        path: 'mitarbeiter/:id',
        name: 'Profil',
        component: () => import(/* webpackChunkName: "MitarbeiterProfile" */ '../views/app/MitarbeiterProfile.vue'),
        meta: { headline: '!Name!' },
      },
      {
        path: 'management/mitarbeiter',
        name: 'Mitarbeiter verwalten',
        component: () => import(/* webpackChunkName: "Mitarbeiter" */ '../views/app/Management/Mitarbeiter.vue'),
        meta: { headline: 'Mitarbeiter verwalten' },
      },
      {
        path: 'management/mitarbeiter/:id',
        name: 'Mitarbeiter Bearbeiten',
        component: () => import(/* webpackChunkName: "EditMitarbeiter" */ '../views/app/Management/EditMitarbeiter.vue'),
        meta: { headline: 'Mitarbeiter bearbeiten' },
      },
      {
        path: 'management/mitarbeiter/new',
        name: 'Mitarbeiter Erstellen',
        component: () => import(/* webpackChunkName: "NewMitarbeiter" */ '../views/app/Management/NewMitarbeiter.vue'),
        meta: { headline: 'Mitarbeiter erstellen' },
      },
      {
        path: 'analyse',
        name: 'Analyse',
        component: () => import(/* webpackChunkName: "Analyse" */ '../views/app/Analyse.vue'),
      },
      {
        path: 'analyse/:id',
        name: 'Analyse Details',
        component: () => import(/* webpackChunkName: "AnalyseDetails" */ '../views/app/AnalyseDetails.vue'),
      },
      {
        path: 'changepassword',
        name: 'Password Ändern',
        component: () => import(/* webpackChunkName: "ChangePassword" */ '../views/app/ChangePassword.vue'),
        meta: { headline: 'Mitarbeiter verwalten' },
      },
      {
        path: 'trainings',
        name: 'TrainingsIndex',
        component: () => import(/* webpackChunkName: "Skills" */ '../views/app/Trainings/Index.vue'),
        children: [
          {
            path: ':id',
            name: 'TrainingsDetails',
            component: () => import(/* webpackChunkName: "Skills" */ '../views/app/Trainings/Details.vue'),
          },
        ],
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  if (to.name === 'Login' || store.getters['isLoggedIn']) {
    if (to.name === 'Login' && store.getters['isLoggedIn']) {
      next({ name: 'Appindex' });
    } else {
      next();
    }
  } else {
    next({ name: 'Login' });
  }
});

export default router;

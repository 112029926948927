<template>
  <div class="topline"></div>
</template>

<style lang="scss">
.topline {
  display:none;
  @media(max-width:1200px) {
    
  }
}
</style>

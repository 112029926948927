<style lang="scss" scoped>
.dimcontainer {
  display: grid;
  grid-template-areas:
    'number1 number2'
    'label1 label2';
  grid-template-columns: 1fr 1fr;
  width: 122px;

  margin: 0 auto;

  $overlap: 10px;
  .number1 {
    grid-area: number1;
  }
  .number2 {
    grid-area: number2;
  }

  .overlap.number1 {
    transform: translateX($overlap);
    z-index: 2;
  }
  .overlap.number2 {
    transform: translateX(-$overlap);
    z-index: 1;
  }
  .label1 {
    grid-area: label1;
  }
  .label2 {
    grid-area: label2;
  }

  .number {
    display: inline-block;
    display: inline-block;
    margin: 0 auto;
    width: 50px;
    height: 50px;
    position: relative;
    margin-bottom: 10px;

    span {
      font-size: 18px;
      position: absolute;
      //font-weight: bold;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .label {
    display: inline-block;

    font-size: 8px;
    font-family: 'HelveticaNeueLTPro-Bd';
    text-transform: uppercase;
  }
}
</style>

<template>
  <div class="dimcontainer">
    <div class="number number1 point point-kreativitaet" :class="{ overlap }">
      <span>{{ dims[0].value }}</span>
    </div>
    <div class="number number2 point point-konzeption" :class="{ overlap }">
      <span>{{ dims[1].value }}</span>
    </div>
    <div class="label label1" v-if="!nolabels">{{ dims[0].name }}</div>
    <div class="label label2" v-if="!nolabels">{{ dims[1].name }}</div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue';

export default defineComponent({
  props: {
    dims: { type: Array, required: true },
    nolabels: { type: Boolean, default: false },
    overlap: { type: Boolean, default: false },
  },
  setup() {
    const convertClass = inject('convertClass') as Function;

    return {
      convertClass,
    };
  },
});
</script>


import { defineComponent, ref } from 'vue';
import { AxiosError, AxiosResponse } from 'axios';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
export default defineComponent({
  setup() {
    const username = ref('');
    const password = ref('');
    const store = useStore();
    const router = useRouter();
    const error = ref('');

    const onLoginClick = () => {
      store
        .dispatch('login', { username: username.value, password: password.value })
        .then((res: AxiosResponse) => {
          //console.log('Redirecting...');
          router.push('/app');
        })
        .catch((err: string) => {
          error.value = err;
        });
    };

    const openState = ref(false);

    return {
      username,
      password,
      onLoginClick,
      openState,
      error,
    };
  },
});

import { createStore } from 'vuex';
import currentUser from '@/store/currentUser';
import skills from '@/store/skills';
import notifications from '@/store/notifications';
import trainings from '@/store/trainings';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import createPersistedState from 'vuex-persistedstate';
import { set } from './_util';

//const api = 'http://localhost:8142';
const api = 'https://skillsplattform.smd-digital.at/api';

interface APIRequest {
  method: 'get' | 'delete' | 'post' | 'put' | 'patch';
  url: string;
  data?: any;
  config?: any;
}

export default createStore({
  modules: {
    currentUser,
    skills,
    notifications,
    trainings,
  },
  plugins: [createPersistedState()],
  state: {
    teams: undefined,
    locations: undefined,
    menustate: false,
    collapsiblestate: {},
  },
  getters: {
    getAvatarUrl(context) {
      return (avatar: string) => {
        if (avatar === undefined) avatar = 'default4.png';
        return `${api}/avatar/${avatar}`;
      };
    },
  },
  mutations: {
    setTeams: set('teams'),
    setLocations: set('locations'),
    setMenuState: set('menustate'),
  },
  actions: {
    setMenuState(context, value) {
      console.log('Setting', value);
      context.commit('setMenuState', value);
    },
    API(context, { method, url, data, config }: APIRequest) {
      const options: AxiosRequestConfig = {
        method,
        url: api + url,
        data,
        headers: {},
        ...config,
      };

      options.headers['access-token'] = context.getters['getAccessToken'];

      return axios(options).catch((err: AxiosError) => {
        // Handle Retry and refresh with refreshtoken
        if (err?.response?.status === 401) {
          if (url == '/auth/refresh') {
            return context.dispatch('logout');
          }

          return context
            .dispatch('refresh')
            .then(() => {
              options.headers['access-token'] = context.getters['getAccessToken'];
              return axios(options).catch((err: AxiosError) => {
                return context.dispatch('logout');
              });
            })
            .catch((err: AxiosError) => {
              return context.dispatch('logout');
            });
        }
        return Promise.reject(err);
      });
    },

    fetchMeta(context: any) {
      return context.dispatch('API', { method: 'get', url: `/meta` }).then((res: AxiosResponse) => res.data);
    },

    getTeams(context) {
      if (context.state.teams) return context.state.teams;

      context.dispatch('API', { method: 'get', url: '/list/teams' }).then((res) => {
        context.commit('setTeams', res.data);

        return context.state.teams;
      });
    },
    getLocations(context) {
      if (context.state.locations) return context.state.locations;

      context.dispatch('API', { method: 'get', url: '/list/locations' }).then((res) => {
        context.commit('setLocations', res.data);

        return context.state.locations;
      });
    },
  },
});

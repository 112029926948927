import { AxiosResponse } from 'axios';
import { set } from './_util';

const state = {
  categories: ['Kampagnen', 'Illustration & Graphic Recording', 'Texten', 'Layout & Grafik', 'Inhouse', 'Sonstiges'],
};

const actions = {
  fetchTrainings(context: any) {
    return context.dispatch('API', { method: 'get', url: '/trainings' }).then((res: AxiosResponse) => res.data);
  },
};

const getters = {};
const mutations = {
  setTrainings: set('trainings'),
};

export default {
  state,
  getters,
  mutations,
  actions,
};


import LoginForm from '../components/Login/LoginForm.vue';
import LogoText from '../components/Login/LogoText.vue';
import { defineComponent, inject, ref } from 'vue';

export default defineComponent({
  components: {
    LoginForm,
    LogoText,
  },
  setup() {
    const variant = ref(1);

    return {
      variant,
      breakpoint: inject('breakpoint'),
    };
  },
});

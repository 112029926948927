<style lang="scss">
$transition: all 0.5s linear;

#form {
  display: inline-block;
  clear: both;
  transition: $transition;
  // overflow: hidden;
  color: #000;
  padding: 0;

  .alert {
    margin-top: 25px;
  }

  button {
    margin-top: 25px;
  }

  &.closed {
    text-align: center;
  }

  .fadeForm-enter-active {
    transition: $transition;
  }

  .fadeForm-enter-from {
    opacity: 0;
  }

  .fadeForm-enter-to {
    opacity: 1;
  }

  .form-element {}

  .w-input__input-wrap {
    height: auto;
    border: 0;
    margin-bottom: 15px;

    .w-input__label {
      text-transform: uppercase;
      display: block;
      color: #333;
      letter-spacing: 1px;
      padding-left: 10px;
      font-size: 90%;
    }

    .w-input__input {
      border: 1px solid #ccc;
      background-color: none !important;
      padding: 10px;
      height: auto;
    }
  }

  .w-input__input-wrap--underline:after {
    border-bottom: 2px solid $c-smd-lachs;
  }

  .w-input--focused.w-input--floating-label .w-input__label--inside,
  .w-input--filled.w-input--floating-label .w-input__label--inside,
  .w-input--has-placeholder.w-input--floating-label .w-input__label--inside {
    padding-bottom: 8px;
    padding-left: 0;
  }

  button {
    padding: 15px 25px;
    border: 1px solid #ccc;
  }

  &.open {
    background-color: none;
    //box-shadow: 0 0 60px inset rgba(0, 0, 0, 0.1);
    width: 100%;

  }

  input {
    filter: none;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    //border: 1px solid green;
    //-webkit-text-fill-color: green;
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
    transition: background-color 5000s ease-in-out 0s;
  }
}
</style>

<template>
  <div id="form" :class="openState ? 'open' : 'closed'">
    <button @click="openState = true" v-if="!openState">Einloggen</button>

    <transition name="fadeForm">
      <div class="form" v-if="openState">
        <div class="row">
          <div class="col-md-4 col-sm-6">
            <div class="form-element">
              <label for="username" :class="{ hasvalue: username != '' }">Benutzername</label>
              <input class="form-control" type="text" id="username" v-model="username" placeholder="Benutzername" />
            </div>
          </div>
          <div class="col-md-4 col-sm-6">
            <div class="form-element">
              <label for="password" :class="{ hasvalue: password != '' }">Passwort</label>
              <input class="form-control" type="password" id="password" v-model="password" placeholder="Password" />
            </div>
          </div>

        </div>
        <div class="row">
          <div class="col-md-3 col-sm-4">
            <button @click="onLoginClick">Einloggen</button>
          </div>
        </div>



        <!-- <input class="ma2" v-model="username" label="Benutzername" />
        <input type="password" v-model="password" label="Passwort" /> -->

        <div class="alert alert-danger" role="alert" v-if="error != ''">
          {{ error }}
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { AxiosError, AxiosResponse } from 'axios';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
export default defineComponent({
  setup() {
    const username = ref('');
    const password = ref('');
    const store = useStore();
    const router = useRouter();
    const error = ref('');

    const onLoginClick = () => {
      store
        .dispatch('login', { username: username.value, password: password.value })
        .then((res: AxiosResponse) => {
          //console.log('Redirecting...');
          router.push('/app');
        })
        .catch((err: string) => {
          error.value = err;
        });
    };

    const openState = ref(false);

    return {
      username,
      password,
      onLoginClick,
      openState,
      error,
    };
  },
});
</script>

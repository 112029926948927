import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import VueSmoothScroll from 'vue3-smooth-scroll';
// @ts-ignore
import VueApexCharts from 'vue3-apexcharts';

import '@mdi/font/css/materialdesignicons.min.css';

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import Icon from './components/util/Icon.vue';
import Points from './components/Points.vue';

const app = createApp(App)
  .component('Icon', Icon)
  .component('Points', Points)
  .use(store)
  .use(router)

  .use(VueSmoothScroll)
  .use(VueApexCharts)
  .mount('#app');

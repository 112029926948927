<style lang="scss">
// VARIANT CODE
.variant-1 {
  #logotext {
    .smd {
      margin-bottom: 40px;
    }
  }
}

#logotext {
  display: inline-block;
  width: 100%;
  padding: 40px 0 20px 0;

  h1 {
    font-size: 53px;
    font-family: 'HelveticaNeueLTPro-Blk';
    line-height: 53px;
    letter-spacing: 5px;
    text-align: left;
    margin-bottom: 40px;
  }

  .smd {
    width: 100%;
    max-width: 250px;
    margin-left: 25px;
  }
}
</style>

<template>
  <div id="logotext">
    <img :src="`${require('@/assets/smd-logo.svg')}`" class="smd" />
    <!--<h1 class="headline">
      SKILLS MANAGEMENT
    </h1>-->
  </div>
</template>

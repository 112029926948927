
import { computed, defineComponent, inject, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
export default defineComponent({
  setup() {
    const store = useStore();
    const showState = ref(false);
    const showRoute = ref(false);
    const route = useRoute();
    return {
      role: store.getters['getUserRole'],
      showState,
      store,
      route,
      showRoute,
      toggleRoute: () => (showRoute.value = !showRoute.value),
      toggleState: () => (showState.value = !showState.value),
      breakpoint: inject('breakpoint'),
      menustate: computed(() => store.state.menustate),
    };
  },
});

<style lang="scss">
// VARIANT CODE
#login {
  padding-left: 0;
  height: 100vh;
  overflow: hidden;
  padding-bottom: 30px;

  .switcher {
    position: fixed;
    bottom: 30px;
    left: 25px;
    text-align: center;

    .w-button {
      font-size: 90%;
      background: $c-smd-yellow;
      color: #333;
    }
  }

  &.variant-1 {
    background-color: #e5ecf7;
    background-image: url('../assets/login-screen-icons.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center right;


    .textcontainer {
      padding-left: 80px;
    }

    @media (max-width: 600px) {
      .sm8 {
        padding-left: 0;
      }

      .sidebar {
        display: none;
      }

      .logotext {
        img {
          margin-bottom: 80px;
        }
      }
    }
  }

  &.variant-2 {
    flex-wrap: wrap;
    overflow: hidden;

    margin-top: -5$topline-height;

    .width-box {

      max-width: 1140px;
      margin: 0 auto;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }

    .picture {
      background-color: #f0f0f0;
    }

    .logotext {
      margin: 0 auto;
    }

    .banner {

      display: block;
      position: relative;
      margin: 0 auto 20px auto;

      //height: 500px;
      .iframe-box {
        position: relative;
        overflow: hidden;
        width: 100%;
        padding-top: 40.25%;
      }

      iframe {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
      }
    }

    .login-box-outer {
      padding: 0 15px;

      h1 {
        font-size: 46px;
        font-family: 'HelveticaNeueLTPro-Blk';
        line-height: 40px;
        letter-spacing: 5px;
        text-align: left;
        margin-bottom: 40px;
        margin-top: 40px;
      }
    }

    @media (max-width: 768px) {
      max-height: auto;
      height: auto;

      .logotext {
        margin: 30px auto;
      }

      .loginform {
        margin: 20px auto;
      }

      .picture {
        display: block;
        width: 100%;
        height: auto;

        img {
          display: block;
          max-width: 100%;
        }
      }
    }
  }
}
</style>

<template>
  <div id="login" class="justify-space-around container-fluid variant-2">
    <div class="width-box">
      <div class="row h-100 justify-content-center">


        <div :class="[breakpoint.name == 'xs' ? 'col-xs-10' : 'col-xs-12 pa12']"
          class="textcontainer col-sm-12 align-self-center" v-if="variant == 1">
          <LogoText class="logotext" />
          <div class="banner">
            <div class="iframe-box">
              <iframe src="https://styriamediadesign.at/skin/banner/startseite/index.html" frameborder="0"></iframe>
            </div>

          </div>
          <div class="login-box-outer">

            <h1 class="headline">SKILLS MANAGEMENT</h1>
            <LoginForm class="loginform mt10" />
          </div>

        </div>

        <div class="picture col-xs-12 text-center pt5" v-if="variant == 2">
          <img :src="`${require('@/assets/login.jpg')}`" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import LoginForm from '../components/Login/LoginForm.vue';
import LogoText from '../components/Login/LogoText.vue';
import { defineComponent, inject, ref } from 'vue';

export default defineComponent({
  components: {
    LoginForm,
    LogoText,
  },
  setup() {
    const variant = ref(1);

    return {
      variant,
      breakpoint: inject('breakpoint'),
    };
  },
});
</script>

import { AxiosError, AxiosResponse } from 'axios';
import jwtDecode from 'jwt-decode';
import { set } from './_util';
import router from '../router';

interface Token {
  token: string;
  exp: number;
  role?: number;
}

const state = {
  userId: null,
  userNameFirst: null,
  userNameLast: null,
  userEmail: null,
  userAvatar: null,
  userTeam: null,
  userLocation: null,
  userAbsprachen: null,
  userLogin: null,
  //userRole: null,
  refreshToken: null,
  refreshTokenId: null,
  refreshTokenExpiration: null,
  accessToken: null,
  accessTokenExpiration: null,
};

const actions = {
  setAbsprachen(context: any, absprache: string) {
    console.log("Update absprachen");
    context.commit('setUserAbsprachen', absprache);
  },
  login(context: any, payload: { username: string; password: string }) {
    return context
      .dispatch('API', { url: '/auth/login', method: 'post', data: { login: payload.username, password: payload.password } })
      .then((res: AxiosResponse) => {
        const refreshToken: Token = jwtDecode(res.data.refreshToken);
        const accessToken: Token = jwtDecode(res.data.accessToken);

        context.commit('setAccessToken', res.data.accessToken);
        context.commit('setRefreshToken', res.data.refreshToken);
        context.commit('setUserAvatar', res.data.userAvatar);
        context.commit('setUserEmail', res.data.userEmail);
        context.commit('setUserId', res.data.userId);
        context.commit('setUserLocation', res.data.userLocation);
        context.commit('setUserAbsprachen', res.data.userAbsprachen);
        context.commit('setUserLogin', res.data.userLogin);
        context.commit('setUserNameFirst', res.data.userNameFirst);
        context.commit('setUserNameLast', res.data.userNameLast);
        //context.commit('setUserRole', res.data.userRole);
        context.commit('setUserTeam', res.data.userTeam);
        context.commit('setUserId', res.data.userId);

        context.commit('setRefreshTokenId', refreshToken.token);
        context.commit('setRefreshTokenExpiration', refreshToken.exp);
        context.commit('setAccessTokenExpiration', accessToken.exp);

        return Promise.resolve(res);
      })
      .catch((err: AxiosError) => {
        return Promise.reject(err.response?.data || 'Systemfehler');
      });
  },
  logout(context: any) {
    const user = context.state.userId;
    const token = context.state.refreshTokenId;
    context.commit('clearSession');
    localStorage.removeItem('vuex');

    router.replace({ name: 'Login' });
    return context.dispatch('API', { url: '/auth/logout', method: 'post', data: { user, token } });
  },
  refresh(context: any) {
    return context.dispatch('API', { url: '/auth/refresh', method: 'post', data: { refreshToken: context.state.refreshToken } }).then((res: AxiosResponse) => {
      const accessToken: Token = jwtDecode(res.data.accessToken);
      context.commit('setAccessToken', res.data.accessToken);
      context.commit('setAccessTokenExpiration', accessToken.exp);
      //context.commit('setUserRole', accessToken.role);
      context.commit('setUserId', res.data.userId);
      context.commit('setUserAvatar', res.data.userAvatar);
      context.commit('setUserNameFirst', res.data.userNameFirst);
      context.commit('setUserNameLast', res.data.userNameLast);
      context.commit('setUserEmail', res.data.userEmail);
      context.commit('setUserTeam', res.data.userTeam);
      context.commit('setUserLocation', res.data.userLocation);
      context.commit('setUserLogin', res.data.userLogin);
      context.commit('setUserAbsprachen', res.data.userAbsprachen);

      return Promise.resolve();
    });
  },
  savePassword(context: any, password: string) {
    return context.dispatch('API', { url: '/user/password', method: 'post', data: { password } });
  },
};

const getters = {
  isLoggedIn: (state: any) => {
    return state.accessToken !== null;
  },
  getAccessToken: (state: any) => {
    return state.accessToken;
  },
  getRoleName: (state: any) => (role: number) => {
    if (role <= 10) {
      return 'Mitarbeiter';
    }

    if (role <= 20) {
      return 'Teamleiter';
    }

    if (role <= 30) {
      return 'Management';
    }

    return 'Administrator';
  },
  getFullName: (state: any) => {
    return `${state.userNameFirst} ${state.userNameLast}`;
  },
  getAvatar: (state: any) => {
    return state.userAvatar;
  },
  getUserRole: (state: any) => {
    try {
      return (jwtDecode(state.accessToken) as Token).role;
    } catch {
      return 0;
    }
  },
  getUserAbsprachen: (state: any) => {
    return state.userAbsprachen;
  },
  getUserID(state: any) {
    return state.userId;
  }
};
const mutations = {
  clearSession: (state: any) => {
    state.userId = null;
    state.userNameFirst = null;
    state.userNameLast = null;
    state.userEmail = null;
    state.userAvatar = null;
    state.userTeam = null;
    state.userLocation = null;
    state.userLogin = null;
    //state.userRole = null;
    state.refreshToken = null;
    state.refreshTokenId = null;
    state.refreshTokenExpiration = null;
    state.accessToken = null;
    state.accessTokenExpiration = null;
  },
  setAccessToken: set('accessToken'),
  setRefreshToken: set('refreshToken'),
  setUserAvatar: set('userAvatar'),
  setUserEmail: set('userEmail'),
  setUserLocation: set('userLocation'),
  setUserLogin: set('userLogin'),
  setUserNameFirst: set('userNameFirst'),
  setUserNameLast: set('userNameLast'),
  setUserAbsprachen: set('userAbsprachen'),
  setUserRole: set('userRole'),
  setUserTeam: set('userTeam'),
  setUserId: set('userId'),
  setRefreshTokenId: set('refreshTokenId'),
  setRefreshTokenExpiration: set('refreshTokenExpiration'),
  setAccessTokenExpiration: set('accessTokenExpiration'),
};

export default {
  state,
  getters,
  mutations,
  actions,
};
